import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Pdf from '../../images/nathanwu_resume.pdf';

const HomeContainer = styled.div`
  p,
  a {
    padding: 0.1em 0.3em;
    margin: 0 auto;
  }

  h1 {
    padding: 0.1em;
    margin: 0 auto;
  }
`
const StyledTitle = styled.h1`
  color: #16242f;
`

const StyledBlurb = styled.p``

const StyledEmail = styled.a``
const HomeSection = ({ children }) => {
  const data = children
  const mailto = `mailto:${data.email}`
  return (
    <HomeContainer>
      <StyledTitle>{data.name}</StyledTitle>
      <StyledBlurb>
        {data.title}
        {/* &nbsp;at
        <a href={data.link} target="_blank">
          {data.company}
        </a> */}
      </StyledBlurb>
      {/* <StyledEmail href={mailto}>{data.email}</StyledEmail> */}
      <StyledEmail href={Pdf} target="_blank">resume</StyledEmail>
    </HomeContainer>
  )
}

export default HomeSection
